<template>
  <div class="mail">
    <div>
      <router-link
        :to="{
          name: 'EmailList'
        }"
      >
        <el-button class="mr-3" type="primary" size="small" @click="submit()">
          返回
        </el-button>
      </router-link>
      <router-link
        :to="{
          name: 'EditEmail',
          params: {
            id: emailId
          }
        }"
      >
        <el-button
          v-if="!model.sent_at"
          type="primary"
          size="small"
          @click="checkEmail()"
          plain
        >
          編輯
        </el-button>
      </router-link>
    </div>
    <hr />
    <div class="mail-header">
      <div class="subject">
        {{ model.subject }}
      </div>
      <div class="user">
        <span class="email-title">寄件人</span>
        <b class="text-success">{{ model.sender }}</b>
      </div>
      <div class="user">
        <span class="email-title">時間</span>
        <span v-if="model.sent_at">
          {{ model.sent_at }}
        </span>
        <div v-else>
          本郵件將在
          <b>{{ model.send_at }} ({{ showDay(model.send_at) }})</b>
          發送到對方信箱
        </div>
      </div>
      <div class="user">
        <span class="email-title">收件人</span>
        <el-tag
          type="success"
          v-for="(user, index) in model.users"
          :key="index"
        >
          {{ user }}
        </el-tag>
      </div>
      <hr />
      <div class="mail-content" v-html="model.content"></div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import mailApi from "@/apis/mail";
import formMixin from "@/mixins/form";
import moment from "moment";

export default {
  metaInfo() {
    return {
      title: "Email Compose - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [formMixin],

  props: {},

  data() {
    return {
      moment: moment,
      model: {
        sender: "Ivy-Way Academy <contact@ivy-way.com>",
        users: [],
        cc_users: [],
        bcc_users: [],
        subject: "",
        content: "",
        is_separate: false,
        is_cc: false,
        is_bcc: false,
        send_at: ""
      }
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    emailId() {
      return this.$route.params.id;
    }
  },
  watch: {},

  async mounted() {
    if (this.emailId) {
      const res = await mailApi.getScheduledMail(this.$route.params.id);
      this.model = { ...res.scheduled_email };
    }
    const { iso_code } = await this.$store.dispatch("ipGeo/getGeoLocation");
    let mail = "contact@ivy-way.com";
    if (iso_code === "CN") {
      mail = "china@ivy-way.com";
    }
    this.model = {
      ...this.model,
      sender: `Ivy-Way Academy <${mail}>`
    };
  },

  methods: {
    showDay(date) {
      const weekArr = ["週日", "週一", "週二", "週三", "週四", "週五", "週六"];
      const weekDay = this.moment(date).format("d");
      return weekArr[weekDay];
    }
  }
};
</script>

<style scoped>
.mail {
  height: 100%;
  padding: 1.5rem 2rem;
  background: #d0e7d9;
  overflow: auto;
}
::v-deep .el-form-item__label {
  margin-bottom: 0;
}
.subject {
  font-size: 1.5rem;
  font-weight: 700;
}
.user {
  display: flex;
  height: 2rem;
  line-height: 2rem;
}
.user .email-title {
  display: flex;
  justify-items: space-between;
  text-align: justify;
  justify-content: space-between;
  width: 4rem;
}
.mail-content {
  background: white;
  min-height: 30rem;
  padding: 1rem;
}
</style>
