var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mail"},[_c('div',[_c('router-link',{attrs:{"to":{
        name: 'EmailList'
      }}},[_c('el-button',{staticClass:"mr-3",attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" 返回 ")])],1),_c('router-link',{attrs:{"to":{
        name: 'EditEmail',
        params: {
          id: _vm.emailId
        }
      }}},[(!_vm.model.sent_at)?_c('el-button',{attrs:{"type":"primary","size":"small","plain":""},on:{"click":function($event){return _vm.checkEmail()}}},[_vm._v(" 編輯 ")]):_vm._e()],1)],1),_c('hr'),_c('div',{staticClass:"mail-header"},[_c('div',{staticClass:"subject"},[_vm._v(" "+_vm._s(_vm.model.subject)+" ")]),_c('div',{staticClass:"user"},[_c('span',{staticClass:"email-title"},[_vm._v("寄件人")]),_c('b',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.model.sender))])]),_c('div',{staticClass:"user"},[_c('span',{staticClass:"email-title"},[_vm._v("時間")]),(_vm.model.sent_at)?_c('span',[_vm._v(" "+_vm._s(_vm.model.sent_at)+" ")]):_c('div',[_vm._v(" 本郵件將在 "),_c('b',[_vm._v(_vm._s(_vm.model.send_at)+" ("+_vm._s(_vm.showDay(_vm.model.send_at))+")")]),_vm._v(" 發送到對方信箱 ")])]),_c('div',{staticClass:"user"},[_c('span',{staticClass:"email-title"},[_vm._v("收件人")]),_vm._l((_vm.model.users),function(user,index){return _c('el-tag',{key:index,attrs:{"type":"success"}},[_vm._v(" "+_vm._s(user)+" ")])})],2),_c('hr'),_c('div',{staticClass:"mail-content",domProps:{"innerHTML":_vm._s(_vm.model.content)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }